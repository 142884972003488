<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Usuarios institucionales asignados a proyectos
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6">
              <v-btn
                color=""
                dark
                class="mb-2 float-right light-blue-502"
                @click="modalNuevo"
              >
                Registrar actividad
              </v-btn>
            </v-col> -->
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="items"
          :search="filtro"
          :loading="tableLoading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class=""
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Nombre del usuario/proyecto"
                    maxlength="200"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <!-- <td>{{ item.id }}</td> -->
              <td>{{ item.usuario }}</td>
              <td>{{ item.nombreUsuario }}</td>
              <td>{{ item.tipoProyecto }}</td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.proyecto }}</td>

              <!-- <td>
                  {{
                    item.nombreMaximaAutoridad != null
                      ? item.nombreMaximaAutoridad
                      : ""
                  }}
                </td>-->
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadoAsignacionId === 0
                      ? 'blue-grey lighten-4'
                      : item.estadoAsignacionId === 1
                      ? 'light-blue lighten-4'
                      : item.estadoAsignacionId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadoAsignacionId === 6
                      ? 'cyan lighten-4'
                      : item.estadoAsignacionId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadoAsignacionId === 0
                      ? 'blue-grey lighten-1'
                      : item.estadoAsignacionId === 1
                      ? 'light-blue lighten-1'
                      : item.estadoAsignacionId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadoAsignacionId === 6
                      ? 'cyan darken-1'
                      : item.estadoAsignacionId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{
                    item.estadoAsignacionId === 0
                      ? `Sin usuario(s) asignado(s)`
                      : item.estadoAsignacion
                  }}
                </v-chip>
              </td>
              <td>
                <!-- <v-btn
                    class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                    small
                    depressed
                    color="blue-grey lighten-5"
                    @click="obtenerDatosItem(item.id)"
                  >
                    <v-icon left>mdi-pencil</v-icon> Inhabilitar asignación
                  </v-btn>      -->

                <v-btn
                  v-if="
                    item.estadoAsignacionId === 1 ||
                      item.estadoAsignacionId === 2
                  "
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarDialogConfirmacion(
                      item.id,
                      item.usuario,
                      item.nombreUsuario,
                      item.estadoAsignacion,
                      item.estadoAsignacionId,
                      item.proyecto,
                      item.tipoProyecto
                    )
                  "
                >
                  <v-icon left>{{
                    item.estadoAsignacionId === 1
                      ? `mdi-account-remove`
                      : `mdi-account-check`
                  }}</v-icon>
                  {{
                    item.estadoAsignacionId === 1
                      ? `Inhabilitar asignación`
                      : `Habilitar asignación`
                  }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
              {{ code4.html }}
            </template>
            <template v-slot:js>
              {{ code4.js }}
            </template> -->
    </v-card>

    <!--begin:: dialog suspension -->
    <v-dialog
      v-model="dialogSuspension"
      width="600"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ tipoAccion }} usuario
        </v-card-title>

        <v-card-text>
          <v-row class="mt-4">
            <v-col sm="12">
              <p class="text-h6">
                Está seguro de {{ tipoAccion }} el usuario
                <span class="black--text">{{ usuario }}</span> del proyecto
                <span class="black--text">{{ proyecto }}</span>
              </p>
            </v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-0 pb-0"
              v-if="this.datosAsignacion.nuevoEstadoId === 2"
            >
              <v-alert outlined type="warning" dense>
                Esta acción deshabilitará el acceso del usuario al proyecto
                <span class="black--text">{{ proyecto }}</span>
              </v-alert>
            </v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="pt-0 pb-0"
              v-if="this.datosAsignacion.nuevoEstadoId === 1"
            >
              <v-alert outlined type="info" dense>
                Esta acción habilitará nuevamente el acceso del usuario al
                proyecto <span class="black--text">{{ proyecto }}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="
              dialogSuspension = false;
              resetForm();
            "
            :disabled="btnRegistroLoading"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="light-blue-502"
            class="white--text mb-2 float-right"
            type="submit"
            dark
            :loading="btnRegistroLoading"
            @click="actualizarEstadoItem()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--dialog para confirmar el cambio del estado-->
    <v-dialog
      v-model="dialogConfirmarEstado"
      transition="scroll-y-transition"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Confirmar
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="false"
            @click="dialogConfirmarEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="letter-spacing: 0.1px !important">
          <v-row class="mt-2">
            <v-col cols="12" md="12" xs="12">
              <p class="text-h6 black--text  pb-0 mb-0">
                Está seguro de cambiar la actividad "{{ nombreItem }}" al estado
                {{ nuevoEstado }}?
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="dialogConfirmarEstado = false"
            :disabled="btncambioEstadoLoading"
          >
            Cancelar
          </v-btn>
          <!--:disabled="!validDocForm" type="submit"-->
          <v-btn
            color="light-blue-502"
            class="white--text mb-2 float-right"
            type="submit"
            :elevation="0"
            :loading="btncambioEstadoLoading"
            @click="actualizarEstadoItem(datosItem.id, nuevoEstadoId)"
          >
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Dialog mapa-->

    <!--end:: modal registro-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";

import validations from "@/core/untils/validations.js";

import {
  OBTENER_USUARIOS_PROYECTOS,
  ACTUALIZAR_USUARIO_PROYECTO,
} from "@/core/services/store/usuarios/usuarioproyecto.module";

export default {
  name: "AdminUnidadesEjecutoras",
  data() {
    return {
      validForm: false,
      tipoAccion: "",
      estadoAsignacion: "",
      proyecto: "",
      usuario: "",
      nombreUsuario: "",
      ddRolesLoading: false,
      dialogRegistro: false,
      dialogConfirmarEstado: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      tableLoading: false,
      dialogSuspension: false,
      switchItemEstado: true,
      proyectoSeleccionado: "",
      filtro: "",
      dialog: false,
      modalTitle: "Registrar Subdivisión",
      accion: 1,
      items: [],
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyecto: "",
      datosItem: {
        id: 0,
        nombreActividad: "",
        descripcion: "",
        estadosId: 1,
      },
      estado: "",
      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Activo" },
        { value: 2, text: "Inactivo" },
      ],
      datosAsignacion: {
        id: 0,
        tipoProyectoId: 0,
        proyectoId: 0,
        nuevoEstadoId: 0,
      },
      nuevoEstado: "",
      nuevoEstadoId: 0,
      nombreItem: "",
      btncambioEstadoLoading: false,
      ...validations,
    };
  },
  components: {
    DialogLoader,
    SnackAlert,
  },
  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estado.text;
    },

    /// Obtener unidades ejecutoras
    async obtenerItems() {
      this.items = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_USUARIOS_PROYECTOS, { estadoId: 0 })
        .then((res) => {
          this.items = res.data;
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    modalNuevo() {
      this.accion = 1;
      this.modalTitle = "Registrar Subdivisión";
      this.btnRegistroText = "Guardar";
      this.switchItemEstado = true;
      this.dialogRegistro = true;
    },

    mostrarDialogConfirmacion(
      id,
      usuario,
      nombreUsuario,
      estadoAsignacion,
      estadoAsignacionId,
      proyecto,
      tipoProyectoId
    ) {
      this.tipoAccion = estadoAsignacionId === 1 ? `Inhabilitar` : `Habilitar`;
      this.estadoAsignacion = estadoAsignacion;
      this.proyecto = proyecto;
      this.usuario = usuario;
      this.nombreUsuario = nombreUsuario;
      this.datosAsignacion.id = id;
      //this.datosAsignacion.proyectoId=proyectoId;
      this.datosAsignacion.nuevoEstadoId = estadoAsignacionId === 1 ? 2 : 1;
      this.datosAsignacion.tipoProyectoId = tipoProyectoId;
      this.dialogSuspension = true;
    },

    async actualizarEstadoItem() {
      //?Si accion es 2 se realiza la actualizacion
      this.btnRegistroLoading = true;
      this.$store
        .dispatch(ACTUALIZAR_USUARIO_PROYECTO, this.datosAsignacion)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogRegistro = false;
            this.obtenerItems();
            this.resetForm();
            this.dialogSuspension = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch((error) => {
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
    },

    //Mostrar el dialogo para la suspension
    mostrarDialogSuspension(nombreProyecto) {
      this.proyectoSeleccionado = nombreProyecto;
      this.dialogSuspension = true;
    },

    resetForm() {
      this.datosAsignacion = {
        id: 0,
        tipoProyectoId: 0,
        proyectoId: 0,
        nuevoEstadoId: 0,
      };
    },
  },
  created() {
    this.obtenerItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Administracion" },
      { title: "Asignación Usuarios Proyectos" },
    ]);
  },

  computed: {
    headers() {
      return [
        /* {
            text: "Id",
            align: "start",
            sortable: false,
            value: "codigo"
          }, */
        {
          text: "Usuario",
          align: "start",
          sortable: true,
          value: "usuario",
        },
        {
          text: "Nombre del usuario",
          align: "start",
          sortable: true,
          value: "nombreUsuario",
        },
        {
          text: "Tipo de proyecto",
          align: "start",
          sortable: true,
          value: "tipoProyecto",
        },
        {
          text: "Código del Proyecto",
          align: "start",
          sortable: true,
          value: "codigo",
        },
        {
          text: "Nombre del Proyecto",
          align: "start",
          sortable: true,
          value: "proyecto",
        },

        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoAsignacion",
          filter: this.filtroEstado,
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "",
        },
      ];
    },
  },
};
</script>
